<template>
  <q-dialog :value="show" position="bottom">
    <div class="sku">
      <div class="sku-goods">
        <img :src="getimg(data.sku.img[0])" mode="widthFix" class="sku-goods-img" />
        <div class="sku-goods-info">
          <div class="sku-goods-price">￥{{ data.sku.price }}</div>
          <div class="sku-goods-stock">库存：{{ data.sku.stock }}</div>
          <div class="sku-goods-select">已选：{{ data.sku.name }}</div>
        </div>
      </div>
      <div class="sku-content">
        <div class="sku-title">规格</div>
        <div class="sku-list">
          <span class="sku-item" :class="[item.id == data.sku.id ? 'sku-item-active' : '']"
            v-for="item in data.dbShopGoodsModel" :key="item.id" @click="skuChange(item)">
            {{ item.name }}
          </span>
        </div>
      </div>
      <div class="sku-buy-num">
        <div>购买数量</div>
        <van-stepper v-model="num" />
      </div>
      <div class="sku-bnt">
        <div class="submit-btn" v-if="lx == 1" @click="submit(1)">加入购物车</div>
        <div class="submit-btn buy-btn" v-if="lx == 2 || lx == 3" @click="submit(2)">立即购买</div>
      </div>
    </div>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'
import { Stepper } from 'vant';

export default {
  components: {
    [Stepper.name]: Stepper,
  },
  model: {
    prop: 'show',
    event: 'onChange'
  },
  props: {
    show: {
      type: Boolean,
    },
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    lx: {
      type: [Number, String],
      default: 1
    }
  },
  setup() {
    return {
      num: ref(1)
    }
  },
  methods: {
    onChange() {
    },
    getimg(img) {
      if (img != "") {
        return this.$config.send_url + "Upload/" + img;
      }
    },
    skuChange(sku) {
      this.$emit('skuChange', sku)
    },
    submit() { 
      this.$emit('submit', {
        num: this.num,
        lx: this.lx
      })
    }
  }
}
</script>

<style scoped>
.sku {
  padding: 10px;
  background-color: #fff;
}

.sku-goods {
  position: relative;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(235, 237, 240, .6);
}

.cross-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}

.sku-goods-img {
  width: 100px;
  height: auto;
}

.sku-goods-info {
  flex: 1;
  padding-left: 10px;
}

.sku-goods-price {
  display: flex;
  align-items: center;
  color: rgba(255, 45, 46, 1);
  font-weight: bold;
  font-size: 18px;
}

.goods-yuanjia {
  text-decoration: line-through;
  color: gray;
  font-size: 13px;
  margin-left: 6px;
}

.sku-goods-stock {
  margin-top: 4px;
  color: gray;
  font-size: 14px;
}

.sku-goods-select {
  margin-top: 4px;
  font-size: 14px;
}

.sku-title {
  padding: 10px 0;
  font-weight: bold;
  font-size: 15px;
}

.sku-item {
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 3px 15px;
  font-size: 14px;
  border-radius: 99rem;
  background-color: rgba(233, 229, 229, 0.6);
}

.sku-item-active {
  color: #fff;
  background-color: rgba(253, 40, 49, 1);
}

.sku-buy-num {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
}

.sku-bnt {
  margin-top: 20px;
}

.submit-btn {
  height: 40px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  background: linear-gradient(234.09deg, #FC8146 0%, #FA9A5F 100%);
  border-radius: 46px;
}

.buy-btn {
  background: linear-gradient(93.5deg, #F8124B 0%, #FE2C2D 100%);
}
</style>