<template>
  <div>
    <div class="action">
      <div class="action-left">
        <div class="action-left-item" @click="tolink('shopStore?id=' + data.userid)">
          <img src="@/assets/img/shop/dianpu.png" class="action-icon" />
          <div>店铺</div>
        </div>
        <div class="action-left-item" @click="tolink('chat?receiver=' + data.userid)">
          <img src="@/assets/img/shop/kefu.png" class="action-icon" />
          <div>客服</div>
        </div>
        <div class="action-left-item">
          <img src="@/assets/img/shop//shoucang.png" class="action-icon" />
          <div>收藏</div>
        </div>
      </div>
      <div class="action-right" v-if="lx == 1">
        <div class="action-btn" @click="showSku(1)">加入购物车</div>
        <div class="action-btn buy-btn" @click="showSku(2)">立即购买</div>
      </div>
      <div class="action-right" v-if="lx == 2">
        <div class="action-btn buy-btn round-btn" @click="showSku(3)">立即购买</div>
      </div>
    </div>

    <div class="placeholder"></div>
  </div>
</template>

<script>
export default {
  name: 'GoodsAction',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    lx: {
      type: [String, Number],
      default: 1
    }
  },
  setup() {
    return {
      
    }
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    showSku(e) {
      this.$emit('showSku', e)
    }
  }
}
</script>

<style scoped>
.action {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px -2px 4px  rgba(0, 0, 0, 0.15);
}

.placeholder {
  height: 58px;
}

.action-icon {
  margin: 0 auto 2px; 
  width: 20px;
  display: block;
}

.action-left {
  padding: 0 10px;
  display: flex;
}

.action-left-item {
  padding: 0 14px;
  text-align: center;
  font-size: 12px;
}

.action-right {
  padding-right: 15px;
  flex: 1;
  display: flex;
}

.action-btn {
  flex: 1;
  height: 40px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  background: linear-gradient(234.09deg, #FC8146 0%, #FA9A5F 100%);
  border-radius: 46px 0 0 46px;
}

.buy-btn {
  border-radius: 0px 46px 46px 0px;
  background: linear-gradient(93.5deg, #F8124B 0%, #FE2C2D 100%);
}

.round-btn {
  border-radius: 46px;
}
</style>