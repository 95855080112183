<template>
  <div class="goods" v-if="goods">
    <!-- <ShopNavBar title="商品详情" /> -->

    <van-swipe class="my-swipe" indicator-color="rgba(254, 44, 45, 1)" @change="onChange">
      <!-- <van-swipe-item v-for="(item, index) in goods.sku.slide" :key="index">
        <img :src="getimg(item.serverFileName)" class="swiper-img" v-if="item.type == 'img'" /> 
        <Mvideo :src="getVideo(item.serverFileName)" v-if="item.type == 'video'" />
      </van-swipe-item> -->
      <van-swipe-item v-for="(item, index) in goods.sku.img" :key="index">
        <img :src="getimg(item)" class="swiper-img" /> 
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">{{ current + 1 }}/{{ goods.sku.slide.length }}</div>
      </template>
    </van-swipe>

    <div class="container">
      <div class="row items-center justify-between">
        <div class="goods-price">￥{{ goods.sku.price }} 
          <!-- <span>(${{ priceText }})</span> -->
        </div>
        <div v-if="lx == 1">已销{{ goods.sku.sales }}件</div>
        <div v-if="lx == 2">第{{ shopLuckyResult.number }}期</div>
      </div>
      <div class="goods-name">{{ goods.name }}</div>
      <div class="goods-label">{{ goods.label }}</div>
      <!-- <div class="tag" v-if="lx == 1">
        <span class="tag-left">{{ goods.sku.rangli }}%让利</span>
        <span>上链算力(T): {{ rangliText }}</span>
      </div>
      <div v-if="lx == 2">
        <div class="q-mt-sm">揭晓进度</div>
        <div class="row items-center">
          <div class="col">
            <q-linear-progress :value="shopLuckyResult.speed" size="10px" rounded color="shopcolor" />
          </div>
          <div class="row items-center">
            <img src="@/assets/img/shop/huo.png" width="12" class="q-ml-sm q-mr-xs" />
            <div class="text-shopcolor">{{ (shopLuckyResult.speed * 100).toFixed(2) }}%</div>
          </div>
        </div>
        <div class="text-shopcolor">还差{{ shopLuckyResult.residue }}份揭晓</div>
      </div> -->
    </div>

    <div class="container q-mt-sm" style="padding: 0 10px;">
      <div class="cell" @click="showSku(lx == 1 ? 2 : 3)">
        <div>规格 <span>{{ goods.sku.name }}</span></div>
        <q-icon name="arrow_forward_ios"></q-icon>
      </div>
      <div class="cell">
        <div>保障 <span>免运费 · 平台保障 · 优先发货</span></div>
      </div>
      <div class="cell">
        <div>分类 <span>免运费</span></div>
      </div>
    </div>

    <div class="container store q-mt-sm" v-if="goods.uid > 0">
      <div class="row">
        <div>
          <img :src="getimg(shopUser.shopImg)" width="58" />
        </div>
        <div class="col q-pl-md">
          <div class="row items-center justify-between store-title">
            <div>{{ shopUser.shopName }}</div>
            <span class="store-tolink">进店逛逛</span>
          </div>
          <div class="q-mt-xs">{{ shopUser.shopSheng + shopUser.shopShi + shopUser.shopXian + shopUser.shopAddress }}</div>
          <div class="row q-mt-xs">
            <div class="tag-block">线上商家</div>
            <div class="tag-block">包邮</div>
          </div>
        </div>
      </div>
    </div>

    <div class="details q-mt-sm">
      <div class="details-title">详情</div>
      <div v-html="goods.sku.content"></div>
    </div>

    <Sku v-model="show" :lx="skuBtnLx" :data="goods" @skuChange="skuChange" @submit="submit" />
    <GoodsAction :data="goods" :lx="lx" @showSku="showSku" />
  </div>
</template>

<script>
import { ref } from 'vue'

import { NavBar, Swipe, SwipeItem, Toast } from 'vant';
import GoodsAction from './components/GoodsAction.vue'
import Sku from './components/Sku.vue'
// import Mvideo from '@/components/Mvideo'

export default {
  components: {
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    GoodsAction,
    Sku,
    // Mvideo
  },
  setup() {
    return {
      us: ref(null),
      current: ref(0),
      lx: ref(1),
      goods: ref(null),
      shopLuckyResult: ref({}),
      show: ref(false),
      skuBtnLx: ref(1),
      rangliText: ref(0),
      priceText: ref(0),

      shopUser: ref({})
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.gid = this.$route.query.id
    if(this.$route.query.lx) {
      this.lx = this.$route.query.lx
    }
    if(this.lx == 1) {
      this.getdata()
    } else {
      this.getShopLucky()
    }
  },
  methods: {
    getimg(img) {
			if (img != "") {
				return this.$config.send_url + "Upload/" + img;
			}
		},
    getVideo(video) {
      if (video != "") {
				return this.$config.send_url + "UploadVedio/" + video;
			}
    },
    onChange(index) {
      this.current = index;
    },
    showSku(e) {
      this.skuBtnLx = e
      this.show = true
      this.current = 0
    },
    // 切换规格
    skuChange(sku) {
      this.goods.sku = sku
      this.getRangliText()
    },
    getRangliText() {
      let _this = this
      _this.$request.post(
        "api/ShopGoods/GetRangliText",
        {
          shopModelId: _this.goods.sku.id
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            _this.rangliText = data.rangliText
            _this.priceText = data.priceText
          }
        }
      )
    },
    // 普通商品详情
    getdata() {
      let _this = this
      _this.$request.post(
        "api/ShopGoods/Get",
        {
          id: _this.gid
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            data.dbShopGoodsModel.forEach(item => {
              item.img = item.img.split(",")
              item.price = item.price.toFixed(2)
              if(item.slide) {
                item.slide = JSON.parse(item.slide)
              } else {
                item.slide = [] // 暂，解决无slide商品
              }
            })
            data.sku = data.dbShopGoodsModel[0]
            _this.goods = data
            _this.shopUser = res.data.data.shopUser
            // _this.getRangliText()
          }
        }
      )
    },
    // 幸运购详情
    getShopLucky() {
      let _this = this
      _this.$request.post(
        "api/ShopLucky/Get",
        {
          shopLuckyId: _this.gid
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            let _goods = data.shopGoods
            _goods.shopLucky = data.shopLucky
            _goods.dbShopGoodsModel.forEach(item => {
              item.img = item.img.split(",")
              item.price = item.price.toFixed(2)
              item.slide = JSON.parse(item.slide)
            })
            _goods.sku = _goods.dbShopGoodsModel[0]
            _this.goods = _goods
            
            let shopLuckyResult = data.shopLuckyResult
            shopLuckyResult.speed = shopLuckyResult.buyCount / shopLuckyResult.buyCountMax
            shopLuckyResult.residue = shopLuckyResult.buyCountMax - shopLuckyResult.buyCount
            _this.shopLuckyResult = shopLuckyResult
            _this.getRangliText()
          }
        }
      )
    },
    submit(e) {
      if(e.lx == 3) {
        this.luckyBuy(e)
      } else {
        if (this.goods.sku.stock <= 0) {
          this.$q.dialog({
						message: "库存不足",
					})
          return;
        }
        this.$utils.goodsAddCar(this.goods, e.num, e.lx)
        if(e.lx == 1) Toast('成功加入购物车');
      }
    },
    // 幸运购购买
    luckyBuy(e) {
      let _this = this
      _this.$q.loading.show({
        message: "购买中..."
      })
      _this.$request.post(
        "api/ShopLuckyOrder/CreateOrder",
        {
          userid: _this.us.userid,
          shopLuckyId: _this.gid,
          shopModelId: _this.goods.sku.id,
          buyCount: e.num
        },
        (res) => {
          _this.$q.loading.hide();
          _this.$q.dialog({
						message: res.data.msg,
					}).onOk(() => {
            if(res.data.code == 100) {
              let data = res.data.data
              _this.$router.replace('luckyBuyPay?id=' + data + '&num=' + e.num)
            }
          })
        }
      )
    }
  }
}
</script>

<style scoped>
.goods {
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.my-swipe {
  background-color: #fff;
}

.swiper-img {
  display: block;
  width: 100%;
}

.custom-indicator {
  position: absolute;
  right: 0;
  bottom: 15px;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 20px 0px 0px 20px;
  background: rgba(102, 102, 102, 1);
  color: #fff;
}

.container {
  padding: 15px 10px;
  background-color: #fff;
}

.goods-price {
  color: rgba(255, 45, 46, 1);
  font-size: 24px;
}

.goods-name {
  color: #000;
  font-size: 18px;
  font-weight: bold;
}

.goods-label {
  font-size: 14px;
  color: gray;
}

.tag {
  margin-top: 10px;
  height: 31px;
  line-height: 31px;
  border-radius: 6px;
  color: rgba(253, 40, 49, 1);
  background-color: rgba(254, 232, 235, 1);
  overflow: hidden;
  font-size: 14px;
}

.tag-left {
  margin-right: 10px;
  padding: 0 6px;
  display: inline-block;
  color: #fff;
  background-color: rgba(253, 40, 49, 1);
}

.cell {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}

.cell span {
  margin-left: 6px;
  color: gray;
}

.store {
  font-size: 12px;
}

.store-title {
  font-weight: bold;
  font-size: 14px;
}

.store-title span {
  font-weight: normal;
  color: gray;
}

.tag-block {
  margin-right: 10px;
  padding: 0 10px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  background-color: rgba(255, 45, 46, 1);
}

.details {
  background-color: #fff;
}

.details :deep(img) {
  width: 100%;
  display: block;
}

.details :deep(p) {
  margin: 0;
}

.details-title {
  padding: 10px 0 10px 20px;
  position: relative;
  font-weight: bold;
  font-size: 15px;
}

.details-title::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 50%;
  height: 30%;
  width: 6px;
  transform: translateY(-50%);
  background-color: rgba(255, 45, 46, 1);
  border-radius: 4px;
}

.video-wrap {
  background-color: #000;
}
</style>